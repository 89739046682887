import { ReactNode, useEffect } from "react";
import "./Template.css";
import {Footer} from "@experta/commons-v2.footer";
import {Header} from "@experta/commons-v2.header";
import {Layout} from "@experta/commons-v2.layout"
import {Spinner} from "@experta/commons-v2.spinner"
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { selectShowSpinner, selectTextSpinner } from "../../Store/Spinner/Selectors";
import { selectToken } from "../../Store/Auth/Selectors";
import { useTemplateUtils } from './utils';
import ErrorModal from "../ErrorModal/ErrorModal";
import { useLocation } from "react-router-dom";
import { getPlansAsync } from "../../Store/Plans/Actions";
import { InitiaLQuoteFormProps } from "../../Components/FormQuote/utils";
import { saveToken } from "../../Store/Auth/Slice";
import { getQuoteService } from "../../Services";
import { saveInitialFormData } from "../../Store/Forms/Slice";
import {FloatingButton} from '@experta/commons-v2.floating-button';
import { getEnvVariablesAsync } from "../../Store/Variables/actions";
import { selectEnvVariables } from "../../Store/Variables/selectors";

/**
 * Interface used as props.
 * @name TemplateProps
 */
export interface TemplateProps {
  children: ReactNode;
}

/**
 * Main component.
 * @name Template
 * @param {TemplateProps}
 * @returns {<Template />}
 */

const Template = ({ children }: TemplateProps) => {

  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const showSpinnerValue = useAppSelector(selectShowSpinner);
  const textSpinner = useAppSelector(selectTextSpinner);
  const variables = useAppSelector(selectEnvVariables);
  const history = useHistory();
  const { footerParams, headerLogoUrl, headerParams, paramsCommunicationChannels } = useTemplateUtils();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if(!Object.values(variables).length) {
      dispatch(getEnvVariablesAsync())
    }
  }, [variables, dispatch])

  const handler = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ): void => {
    const url = headerLogoUrl ?? '';
    if(url){
      window.location.href = url
    } else {
      history.push('/');
    }
  };

  useEffect(() => {

    if(history.location.pathname !== '/' && !token) {
      window.location.pathname = "/";
    }

  }, []);

  useEffect(() => {
    
    const url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let hash = params.has("hashid");
    let hash_data = params.get("hashid") as string;

    if (hash) {
      redirectToPlans(hash_data);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(variables)

  //!TODO: Falta revisar circuito
  const redirectToPlans = async (hash: string) => {
    try {
        await dispatch(saveToken(hash));
        const resp = await getQuoteService()?.getPlans(hash);

        if(resp && resp.data && resp.data.bienAsegurado && resp.data.contacto) {
          
          let initialFormData: InitiaLQuoteFormProps = {
            propertyType: resp.data.bienAsegurado.tipoHogar,
            zipCode: resp.data.bienAsegurado.direccion.codPostal,
            locality: resp.data.bienAsegurado.direccion.idLocalizacion,
            firstName: resp.data.contacto.nombre,
            lastName: resp.data.contacto.apellido,
            email: resp.data.contacto.email,
            prefix: resp.data.contacto.celular.prefijo,
            phone: resp.data.contacto.celular.numero,
          };

          await dispatch(saveInitialFormData(initialFormData));
          await dispatch(getPlansAsync(hash));
          history.push(`/planes/${hash}`);

        } else {
          throw ("invalid base64 format.");
        }

        //await dispatch(saveInicialFormData(formData));
        //history.push("/planes");
        // throw ("invalid base64 format.");

    } catch (error) {
      console.error(error);
      window.location.href = (window.location.href).split('?')[0] ;
    }
  };

  const checkRoute = () => {
    return !(history.location.pathname !== '/' && !token);
  };

  return <>
    { checkRoute() &&
      (
        <div className="template-ap container-fluid p-0" style={ { display: (new URLSearchParams((new URL(window.location.href)).search)).has("hashid") ? 'none' : '' } }>
          <Spinner show={showSpinnerValue} text={textSpinner} />
          <Layout>
            <ErrorModal />
            {
              Object.values(variables).length
              ? (
                <Header
                  logoClickHandler={handler}
                  backgroundColor={headerParams.backgroundColor}
                  logoURL={headerParams.logo}
                />
              )
              : <></>
            }
            {children}
            {
              Object.values(variables).length
              ? (
                <>
                  <FloatingButton 
                    communicationChannels={ paramsCommunicationChannels.data }
                    alwaysShowTitle={ false }
                    // text={ paramsCommunicationChannels.title }
                    icon={ paramsCommunicationChannels.mainIcon }
                    mainButtonStyles={{ backgroundColor: `${ paramsCommunicationChannels.mainColor }` }} 
                  />
                  <Footer links={footerParams.links} legalText={footerParams.textFooter} />
                </>
              )
              : <></>
            }
          </Layout>
        </div>
      )
    }
  </>;
};

export default Template;


