import API from "./api";
import axios, { AxiosRequestConfig } from "axios";


/**
 * Used as PostInitialQuoteProps's property.
 * @name PostInitialQuoteBodyProps
 */
export interface PostInitialQuoteBodyProps {
    tipoProducto: string,
    bienAsegurado: {
        tipoHogar: string,
        direccion: {
            idLocalizacion: string
        }
    }
    contacto: {
        nombre: string,
        apellido: string,
        email: string,
        celular: {
            prefijo: number,
            numero: number,
        }
    }
}




/**
 * Used as parameter on postInitialQuote function.
 * @name PostInitialQuoteProps
 */
export interface PostInitialQuoteProps {
    body: PostInitialQuoteBodyProps,
    config: AxiosRequestConfig
}

/**
 * Used as parameter on reQuote function.
 * @name ReQuoteProps
 */
export interface ReQuoteProps {
    hash: string,
    propertyType: string,
    locality: string,
}

/**
 * Used as parameter on postQuote function.
 * @name PostQuoteProps
 */
export interface PostQuoteProps {
    hash: string,
    id: string
}

/**
 * Used as parameter on emitQuote function.
 * @name EmmitQuoteProps
 */
export interface EmmitQuoteProps {
    hash: string,
    formData: emmitQuoteFormDataProps
}

/**
 * Used as emmitQuoteProps's property.
 * @name emmitQuoteFormDataProps
 */
export interface emmitQuoteFormDataProps {
    tipoPersona: string,
    condicionImpositiva: string,
    dni: string,    
    cuit: string,
    sex: string,
    name: string,
    lastname: string,
    socialReason: string,
    email: string,
    areaCode: string,
    phone: string,
    takerActivity: string,
    nationality: string,
    birthday: string,
    state: string,
    locality: string,
    zipCode: string,
    subcp: string,
    localizationID: string,
    localityID: string,
    propertyType: string,
    startDate: string,
    street: string,
    streetNumber: string,
    floor: string,
    number: string,
    samePlaceCheck: boolean,
    fencesCheck: boolean,
    securityCheck: boolean,
    paymentMethod: string,
    cardNumber?: string,
    expirationMonth?: string,
    expirationYear?: string,
    cardName?: string,
    cardCompany?: string,
    bankId?: string,
    CBU?: string,
    takerStreet: string,
    takerStreetNumber: string,
    takerFloor: string,
    takerNumber: string,
    takerZipCode: string,
    takerLocality: string,
    takerState: string,
    takerSubcp: string,
    takerLocalizationID: string,
    takerLocalityID: string,
    politicallyExposed: boolean,
    obligatedSubject: boolean,
    reason: string
}


/**
 * Interface which describes the response of paseEmmitQuoteBody.
 * @name EmmitQuoteBody
 */
export interface EmmitQuoteBody {
    inicioCobertura: string,
    tomador: {
        actividad: string,
        tipoPersona: string,
        razonSocial?: string | null,
        nombre: string,
        apellido: string,
        fechaNacimiento: string,
        tipoDocumento: string,
        numeroDocumento: string,
        sexo: string | null,
        email: string,
        celular: {
            prefijo: string,
            numero: string
        },
        direccion: {
            calle: string,
            numero: string,
            piso: string,
            departamento: string,
            localidad: string,
            provincia: string,
            codPostal: string,
            subcp: string,
            idLocalizacion: string
        },
        tipoCondicionImpositiva: string,
        esPep: boolean,
        sujetoObligado: boolean,
        nacionalidad: string,
        motivoPep: string
    },
    medioPago: {
        tipo: string,
        cbu: string | null,
        banco: string | null,
        hashMercadoPago: string | null,
        anio: string | null,
        mes: string | null,
        nombreTitular: string | null,
        numeroTarjeta: string | null,
        empresaTarjeta: string | null
    },
    declaracionesJuradas: Array<{ 
        idDeclaracion: string, 
        respuesta: boolean 
    }>,
    bienAsegurado: {
        tipoHogar: string,
        direccion: {
            calle: string,
            numero: string,
            piso: string,
            departamento: string,
            idLocalizacion: string,
            idLocalidad?: string,
            localidad: string,
            codPostal: string,
            subcp: string,
            provincia: string
        }
    }
}

/**
 * Interface which describes the vendor object of a GetPlansResp.
 * @name VendorPlan
 */
export interface VendorPlan {
    nombre: string,
    codigo: string
}

/**
 * Interface which describes the address object of an insured property object.
 * @name InsuredPropertyAddressPlan
 */
export interface InsuredPropertyAddressPlan {
    idLocalizacion: string,
    localidad: string,
    codPostal: string,
    subcp: string,
    provincia: string
}

/**
 * Interface which describes the insured property object of a GetPlansResp.
 * @name insuredPropertyPlan
 */
export interface insuredPropertyPlan {
    tipoHogar: string,
    direccion: InsuredPropertyAddressPlan,
    requiereRejas: boolean
}

/**
 * Interface which describes the coverage object of a GetPlansResp.
 * @name CoveragePlan
 */
export interface CoveragePlan {
    codigoCobertura: string,
    montoAsegurado: string
}

/**
 * Interface which describes the plan object of a GetPlansResp.
 * @name Plan
 */
export interface Plan {
    idPlan: string,
    premioAnual: string,
    premioMensual: string,
    coberturas: Array<CoveragePlan>
}

/**
 * Interface which describes the phone object of a GetPlansResp.
 * @name PhonePlan
 */
export interface PhonePlan {
    prefijo: number,
    numero: number
}

/**
 * Interface which describes the contact object of a GetPlansResp.
 * @name ContactPlan
 */
export interface ContactPlan {
    nombre: string,
    apellido: string,
    email: string,
    celular: PhonePlan
}

/**
 * Interface which describes the cotización object of a GetPlansResp.
 * @name QuotationPlan
 */
export interface QuotationPlan {
    creacion: string,
    vencimiento: string,
    estadoCotizacion: string,
    plan: Plan
}

/**
 * Interface Interface which describes the response of getPlans.
 * @name Plan
 */
export interface GetPlansResp {
    id: number,
    estadoVenta: string,
    fechaCreacion: string,
    vendedor: VendorPlan,
    bienAsegurado: insuredPropertyPlan,
    planes: Array<Plan>,
    contacto: ContactPlan,
    idVenta: number,
    hashId: string,
    fechaVencimiento: string,
    producto: string,
    cotizacion?: QuotationPlan
}

export class QuoteService {

    /**
     * Function that's used to create a quote and returns the hashid.
     * @name postInitialQuote
     */
    public postInitialQuote = async ({ body, config }: PostInitialQuoteProps) => {
        return API.post(`ventas`, body, config).then((res) => {
            //const hashid = res.headers["location"].split("/").pop();
            const hashid = res.data.Location.split("/").pop();
            //sessionStorage.setItem("hash", hashid);
            return hashid;
        })
            .catch((err) => {
                return err;
            });

    };


    /**
     * Function that's used to update the quote.
     * @name reQuote
     */
    public reQuote = async ({ hash, propertyType, locality }: ReQuoteProps) => {
        return API.patch(`ventas/${hash}/bien-asegurado`, {
            tipoHogar: propertyType,
            idLocalizacion: locality,
        })
            .then(() => {
                //Return nothing, just update current quote whith the selected plan... need to call getPlans to update quote store.
            })
            .catch((err) => {
                console.log(err);
            });

    };

    /**
     * Function that's used to get the quote status (plans, coverage, status, etc...).
     * @name getPlans
     */
    public getPlans = async (hash: string) => {
        return API.get(`ventas/${hash}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });

    };


    /**
     * Function that's used to select a plan to a quote.
     * @name postQuote
     */
    public postQuote = async ({ hash, id }: PostQuoteProps) => {
        return API.post(`ventas/${hash}/cotizacion`, { idPlan: id })
            .then((res) => {
                //Return nothing, just update current quote whith the selected plan... need to call getPlans to update quote store.
            })
            .catch((err) => {
                return err;
            });

    };


    /**
     * Function that's used to emmit a quote.
     * @name emmitQuote
     */
    public emmitQuote = async ({ hash, formData }: EmmitQuoteProps) => {
        console.log('parseado: ', this.parseEmmitQuoteBody(formData));
        return API.post(`ventas/${hash}/emision`, this.parseEmmitQuoteBody(formData))
            .then((res) => {
                return res;
            })
            .catch((error) => {
                if (axios.isAxiosError(error) && error.response) {
                    return error.response
                  } else {
                      console.log(error)
                      return error
                  }
            });

    };

    /**
     * Function that's used to parse the body parameter of emmitQuote.
     * @name parseEmmitQuoteBody
     */
    private parseEmmitQuoteBody = (formData: emmitQuoteFormDataProps): EmmitQuoteBody => {
        let data: EmmitQuoteBody = {} as EmmitQuoteBody;

        data.inicioCobertura = formData.startDate;

        let tipoDocumento = "";
        let numeroDocumento = "";
        let sexo = null;
        const socialReason = formData.tipoPersona === "JURIDICA" ? formData.socialReason : null;

        if (
            (formData.tipoPersona === "FISICA" &&
                formData.condicionImpositiva !== "CONSUMIDOR_FINAL") ||
            formData.tipoPersona === "JURIDICA"
        ) {
            tipoDocumento = "CUIT";
            numeroDocumento = formData.cuit;
        } else if (
            formData.tipoPersona === "FISICA" &&
            formData.condicionImpositiva === "CONSUMIDOR_FINAL"
        ) {
            tipoDocumento = "DNI";
            numeroDocumento = formData.dni;
        }
  
        if(formData.tipoPersona === "FISICA") {
          sexo = formData.sex;
        }

        let direccionTomador: any;

        if (formData.samePlaceCheck) {
            direccionTomador = {
                calle: formData.street,
                numero: formData.streetNumber,
                piso: formData.floor,
                departamento: formData.number,
                localidad: formData.locality,
                provincia: formData.state,
                codPostal: formData.zipCode,
                subcp: formData.subcp,
                idLocalizacion: formData.localizationID,
                idLocalidad: formData.localityID,
            };
        } else {
            direccionTomador = {
                calle: formData.takerStreet,
                numero: formData.takerStreetNumber,
                piso: formData.takerFloor,
                departamento: formData.takerNumber,
                provincia: formData.takerState,
                codPostal: formData.takerZipCode,
                subcp: formData.takerSubcp,
                idLocalizacion: formData.takerLocality,
                idLocalidad: formData.takerLocalityID,
            };
        }

        data.tomador = {
            actividad: formData.takerActivity,
            tipoPersona: formData.tipoPersona,
            razonSocial: socialReason,
            nombre: formData.name,
            apellido: formData.lastname,
            tipoDocumento: tipoDocumento,
            numeroDocumento: numeroDocumento,
            sexo: sexo,
            email: formData.email,
            celular: {
                prefijo: formData.areaCode,
                numero: formData.phone,
            },
            fechaNacimiento: formData.birthday,
            direccion: direccionTomador,
            tipoCondicionImpositiva: formData.condicionImpositiva,
            esPep: formData.politicallyExposed,
            sujetoObligado: formData.obligatedSubject,
            nacionalidad: formData.nationality,
            motivoPep: formData.reason
        };

        data.medioPago = {
            tipo: formData.paymentMethod,
            cbu: formData.CBU? formData.CBU : null,
            banco: formData.bankId? formData.bankId : null,
            hashMercadoPago: null,
            anio: formData.expirationYear? formData.expirationYear : null,
            mes: formData.expirationMonth? formData.expirationMonth : null,
            nombreTitular: formData.cardName ? formData.cardName : null,
            numeroTarjeta: formData.cardNumber ? formData.cardNumber: null,
            empresaTarjeta: formData.cardCompany ? formData.cardCompany: null,
        };

        data.declaracionesJuradas = [
            {
                idDeclaracion: "TIENE_REJAS",
                respuesta: formData.fencesCheck,
            },
            {
                idDeclaracion: "CERRADURA_DOBLE_PALETA",
                respuesta: true,
            },
        ];

        data.bienAsegurado = {
            tipoHogar: formData.propertyType,
            direccion: {
                calle: formData.street,
                numero: formData.streetNumber,
                piso: formData.floor,
                departamento: formData.number,
                idLocalizacion: formData.localizationID,
                idLocalidad: formData.localityID,
                localidad: formData.locality,
                codPostal: formData.zipCode,
                subcp: formData.subcp,
                provincia: formData.state,
            },
        };
        return data;
    };

}


