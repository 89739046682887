import { Validations } from "@experta/commons-v2.layout";
import { PaymentDataForm } from "@experta/commons-v2.payment-data/dist/utils";
import { getCardValidator, getCBUValidator } from "../../Services";

export const paymentFormAsyncValidation = async (
  localFormData: PaymentDataForm,
  lang: any
) => {
  const cardValidator = getCardValidator();

  const errorsMessages: Array<{ field: string; message: string }> = [];

  const validateExpirationDate = (
    expirationYear: number,
    expirationMonth: number
  ) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;

    const isValidYear = expirationYear >= currentYear;
    const isValidMonth =
      expirationYear === currentYear
        ? expirationMonth >= currentMonth
        : isValidYear;

    return isValidMonth && isValidYear;
  };

  let cardNumberExists = true;
  let cardCompanyExists = true;

  /*
   * Credit Card sync validations
   */

  if (localFormData.paymentMethod === "CREDITO") {
    
    if (!Validations.required(localFormData.expirationMonth as string)) {
      errorsMessages.push({
        field: "expirationMonth",
        message: lang.default.errors.required,
      });
    } else if (
      !(
        (localFormData.expirationMonth as string).length === 2 &&
        Validations.number(localFormData.expirationMonth as string) &&
        Number(localFormData.expirationMonth) >= 1 &&
        Number(localFormData.expirationMonth) <= 12
      )
    ) {
      errorsMessages.push({
        field: "expirationMonth",
        message: lang.default.errors.invalid,
      });
    }

    if (!Validations.required(localFormData.expirationYear as string)) {
      errorsMessages.push({
        field: "expirationYear",
        message: lang.default.errors.required,
      });
    } else if (
      !(
        (localFormData.expirationYear as string).length === 4 &&
        Validations.number(localFormData.expirationYear as string) &&
        !validateExpirationDate(
          Number(localFormData.expirationMonth),
          Number(localFormData.expirationYear)
        )
      )
    ) {
      errorsMessages.push({
        field: "expirationYear",
        message: lang.default.errors.invalid,
      });
    }

    if (!Validations.required(localFormData.cardName as string)) {
      errorsMessages.push({
        field: "cardName",
        message: lang.default.errors.required,
      });
    } else if (!Validations.fullName(localFormData.cardName as string)) {
      errorsMessages.push({
        field: "cardName",
        message: lang.default.errors.invalid,
      });
    }

    if (!Validations.required(localFormData.cardCompany as string)) {
      cardCompanyExists = false;
      errorsMessages.push({
        field: "cardCompany",
        message: lang.default.errors.required,
      });
    } else {
      cardCompanyExists = true;
    }

    if (!Validations.required(localFormData.cardNumber as string)) {
      cardNumberExists = false;
      errorsMessages.push({
        field: "cardNumber",
        message: lang.default.errors.required,
      });
    } else {
      cardNumberExists = true;
    }

    /*
     * Credit Card async validations
     */

    if (cardCompanyExists && cardNumberExists) {
      try {
        const response = await cardValidator?.validateCard(
          localFormData.cardCompany as string,
          localFormData.cardNumber as string
        );

        if (!response.data?.valido) {
          errorsMessages.push({
            field: "cardNumber",
            message: response.data?.error,
          });
        }
      } catch (error) {
        console.log(error);
        errorsMessages.push({
          field: "cardNumber",
          message: "Numero o marca de tarjeta inválido",
        });
      }
    }
  } else {
    /**
     * CBU sync validation
     */

    const cbuValidator = getCBUValidator();
    let cbuNumberExists = true;

    if (!Validations.required(localFormData.CBU as string)) {
      errorsMessages.push({
        field: "CBU",
        message: lang.default.errors.required,
      });

      cbuNumberExists = false;
    } else {
      cbuNumberExists = true;
    }

    /**
     * CBU async validations
     */

    if (cbuNumberExists) {
      try {
        const response = await cbuValidator?.validateCBU(
          localFormData.CBU as string
        );

        if (!response.data?.valido) {
          errorsMessages.push({
            field: "CBU",
            message: response.data?.error,
          });
        }
      } catch (error) {
        console.log(error);
        errorsMessages.push({
          field: "CBU",
          message: lang.default.invalid,
        });
      }
    }
  }

  return { errorsMessages: errorsMessages };
};
