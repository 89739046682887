import { forwardRef, useEffect, useImperativeHandle, useReducer, useState } from "react";

import "./FormQuote.css";
import * as lang from "./lang.json";
import {
  initialFormQuoteState,
  getInputRadioParams,
  FormQuoteProps,
} from "./utils";

import {Input} from "@experta/commons-v2.input";
import {Button} from "@experta/commons-v2.button";
import {Select} from "@experta/commons-v2.select";
import {InputRadio} from "@experta/commons-v2.input-radio";
import {Recaptcha} from "@experta/commons-v2.recaptcha";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { hideSpinner, showSpinner } from "../../Store/Spinner/Actions";
import { getLocalitiesAsync } from "../../Store/Localities/Actions";
import { selectLocalitiesCombo } from "../../Store/Localities/Selectors";
import { selectEnvVariables } from "../../Store/Variables/selectors";

const FormQuote = ({
  onChange = (data: any) => {},
  onSubmit = () => {},
  validateMethod,
}: FormQuoteProps, ref: any) => {

  const variables = useAppSelector(selectEnvVariables) as any;
  const dispatch = useAppDispatch();
  const localitiesComboSelector = useAppSelector(selectLocalitiesCombo);
  const formReducer = (state: any, event: any) => {
    const newState = {
      ...state,
      [event.name]: event.value,
    };
    onChange(newState);
    return newState;
  };
  const [localities, setLocalities] = useState<any>([]);
  const [formDataErrors, setFormDataErrors] = useState<Array<{field: string, message: string}>>([]);
  const [formData, setFormData] = useReducer(formReducer, initialFormQuoteState);
  const sitekey= variables.REACT_APP_RECAPTCHA_SITEKEY as string;

  const handlerRecaptchaResponse = (recaptcha: any) => {
    if(formData.recaptchaResponse !== recaptcha){
      setFormData({
        name: "recaptchaResponse",
        value: recaptcha,
      });
    }
  };

  const fetchLocalities = async (zipCode:string) => {
    await dispatch(showSpinner());
    await dispatch(getLocalitiesAsync(zipCode));
    await dispatch(hideSpinner());
  }

  const handleChange = (e: any) => {

    if (e.target.name === "zipCode") {
      if (e.target.value.length === 4) {
        setFormData({
          name: e.target.name,
          value: e.target.value,
        });
        fetchLocalities(e.target.value);
      } else {
        if (e.target.value.length < e.target.maxLength) {
          setFormData({
            name: e.target.name,
            value: e.target.value,
          });
        }
      }
    } else {
      setFormData({
        name: e.target.name,
        value: e.target.value,
      });
    }
  };

  useImperativeHandle(ref, () => ({
    validateForm: async () => {
    let result = await validateMethod(formData, lang);
    setFormDataErrors([...result.errorsMessages]);
    return result.errorsMessages;
  }
}), [formData]);

  useEffect(() => {
    setLocalities([...localitiesComboSelector]);
  }, [localitiesComboSelector]);


  const handleSubmit = () => {
    if(formData.recaptchaResponse !== "") {
      onSubmit();
    }
  };

  const hasError = (name: string) => {
    return formDataErrors.filter(el => el.field === name).length > 0;
  };

  const getError = (name: string) => {
    if(hasError(name)){
      return formDataErrors.filter(el => el.field === name)[0].message;
    }
    else {
      return undefined;
    }
  };

  return (
    <div className="form-quote-container p-xl-5 px-lg-4">
      <span className="form-quote-title">{lang.form.title}</span>
      <form className="form-quote">
        <div className="row row-less-spacing ">
          <div className="col-4 col-less-spacing">
            <Input
              name="zipCode"
              label={lang.form.zipCode}
              labelMobile={lang.form.zipCodeMobile}
              onChange={handleChange}
              value={formData.zipCode}
              type="number"
              maxLength={4}
              error={hasError("zipCode")}
              errorMessage={getError("zipCode")}
            />
          </div>
          <div className="col-8 col-less-spacing">
            <Select
              name="locality"
              options={localities}
              label={lang.form.locality}
              onChange={handleChange}
              value={formData.locality}
              error={hasError("locality")}
              errorMessage={getError("locality")}
            />
          </div>
        </div>
        <div className="row row-less-spacing">
          <div className="col-12 col-less-spacing">
            <InputRadio
              inputLabel={getInputRadioParams(formData).inputLabel}
              values={getInputRadioParams(formData).values}
              onChange={handleChange}
            />
          </div>
        </div>
        <p className="form-subtitle">{lang.form.subtitle}</p>
        <div className="row row-less-spacing">
          <div className="col-6 col-less-spacing">
            <Input
              name="firstName"
              label={lang.form.firstName}
              onChange={handleChange}
              value={formData.firstName}
              error={hasError("firstName")}
              errorMessage={getError("firstName")}
            />
          </div>
          <div className="col-6 col-less-spacing">
            <Input
              name="lastName"
              label={lang.form.lastName}
              onChange={handleChange}
              value={formData.lastName}
              error={hasError("lastName")}
              errorMessage={getError("lastName")}
            /> 
          </div>
        </div>
        <div className="row row-less-spacing">
          <div className="col-12 col-less-spacing">
            <Input
              name="email"
              label={lang.form.email}
              onChange={handleChange}
              value={formData.email}
              error={hasError("email")}
              errorMessage={getError("email")}
            />
          </div>
        </div>
        <div className="row row-less-spacing">
          <div className="col-4 col-less-spacing">
            <Input
              name="prefix"
              label={lang.form.prefix}
              onChange={handleChange}
              value={formData.prefix}
              type="number"
              maxLength={4}
              error={hasError("prefix")}
              errorMessage={getError("prefix")}
            />
          </div>
          <div className="col-8 col-less-spacing">
            <Input
              name="phone"
              label={lang.form.phone}
              onChange={handleChange}
              value={formData.phone}
              type="number"
              error={hasError("phone")}
              errorMessage={getError("phone")}
            />
          </div>
        </div>
        {
          sitekey && (
            <Recaptcha
              sitekey={sitekey}
              handlerRecaptchaResponse={handlerRecaptchaResponse}
            />
          )
        }
        <div className="row row-less-spacing justify-content-center">
          <Button
            onClick={() => handleSubmit()}
            className="col-8 col-less-spacing form-quote-button"
            type="button"
            style={ formData.recaptchaResponse === "" ? ({"cursor": "not-allowed",
              "filter": "alpha(opacity=65)",
              "WebkitBoxShadow": "none",
              "boxShadow": "none",
              "opacity": ".65"}) : ({"cursor": "pointer"})}
            
          >
            {lang.buttonText}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default forwardRef(FormQuote);
