import { Validations } from "@experta/commons-v2.layout";
import { InitiaLQuoteFormProps } from "../../Components/FormQuote/utils";
import { getPhoneValidator } from "../../Services";

export const formQuoteAsyncValidation = async (
  localFormData: InitiaLQuoteFormProps,
  lang: any
) => {
  let phonePrefixExists = false;
  let phoneNumberExists = false;

  const errorsMessages: Array<{ field: string; message: string }> = [];

  /**
   * sync validations
   */

  if (!Validations.required(localFormData.firstName)) {
    errorsMessages.push({
      field: "firstName",
      message: lang.default.errors.required,
    });
  } else if (
    !(
      Validations.fullName(localFormData.firstName) &&
      Validations.maxLength(localFormData.firstName, 29)
    )
  ) {
    errorsMessages.push({
      field: "firstName",
      message: lang.default.errors.invalid,
    });
  }

  if (!Validations.required(localFormData.lastName)) {
    errorsMessages.push({
      field: "lastName",
      message: lang.default.errors.required,
    });
  } else if (
    !(
      Validations.fullName(localFormData.lastName) &&
      Validations.maxLength(localFormData.lastName, 29)
    )
  ) {
    errorsMessages.push({
      field: "lastName",
      message: lang.default.errors.invalid,
    });
  }

  if (!Validations.required(localFormData.email)) {
    errorsMessages.push({
      field: "email",
      message: lang.default.errors.required,
    });
  } else if (!Validations.email(localFormData.email)) {
    errorsMessages.push({
      field: "email",
      message: lang.default.errors.invalid,
    });
  }

  if (!Validations.required(localFormData.prefix)) {
    errorsMessages.push({
      field: "prefix",
      message: lang.default.errors.required,
    });
    phonePrefixExists = false;
  } else {
    phonePrefixExists = true;
  }

  if (!Validations.required(localFormData.phone)) {
    errorsMessages.push({
      field: "phone",
      message: lang.default.errors.required,
    });
    phoneNumberExists = false;
  } else {
    phoneNumberExists = true;
  }

  if (!Validations.required(localFormData.zipCode)) {
    errorsMessages.push({
      field: "zipCode",
      message: lang.default.errors.required,
    });
  } else if (!Validations.number(localFormData.zipCode)) {
    errorsMessages.push({
      field: "zipCode",
      message: lang.default.errors.invalid,
    });
  }

  if (!Validations.required(localFormData.locality)) {
    errorsMessages.push({
      field: "locality",
      message: lang.default.errors.required,
    });
  }

  /**
   * Async validations
   */

  if (phonePrefixExists && phoneNumberExists) {
    const phoneValidator = getPhoneValidator();

    try {
      const response = await phoneValidator?.validatePhone(
        localFormData.prefix,
        localFormData.phone
      );
      if (!response.data?.valido) {
        const field = response.data?.campo === "numero" ? "phone" : "prefix";

        if (field) {
          errorsMessages.push({
            field: field,
            message: response.data?.error || "Campo inválido",
          });
        } else {
          errorsMessages.push({
            field: "phone",
            message: "Campo inválido",
          });
          errorsMessages.push({
            field: "prefix",
            message: "Campo inválido",
          });
        }
      }
    } catch (error) {
      console.log(error);
      errorsMessages.push({ field: "phone", message: "Teléfono inválido" });
    }
  }

  return { errorsMessages: errorsMessages };
};
