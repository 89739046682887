/* eslint-disable react-hooks/exhaustive-deps */
import "./Payment.css";
import * as lang from "./lang.json";

import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import {InfoHeader} from "@experta/commons-v2.info-header";
import {StepsPayment} from "@experta/commons-v2.steps-payment";
import {Button} from "@experta/commons-v2.button";
import {PaymentPrivacyBanner} from "@experta/commons-v2.payment-privacy-banner";

import iconQuote from "../../assets/Icons/ICONO_COTIZACION.svg";
import iconPayment from "../../assets/Icons/ICONO_PAGO_white.svg";
import iconPlans from "../../assets/Icons/ICONO_PLANES.svg";

import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { hideSpinner, showSpinner } from "../../Store/Spinner/Actions";
import { selectBienAsegurados, selectSelectedPlan } from "../../Store/Plans/Selectors";
import { getLocalitiesAsync } from "../../Store/Localities/Actions";
import {
  selectLocalities,
  selectLocalitiesCombo,
} from "../../Store/Localities/Selectors";
import { savePaymentFormData } from "../../Store/Payment/Slice";
import PropertyData from "../../Parts/Forms/PropertyData/PropertyData";
import PersonalData from "../../Parts/Forms/PersonalData/PersonalData";
import {
  PersonalPaymentDataFormProps,
  PropertyPaymentDataFormProps,
  cardBrands,
  formatPlanName,
  monthDiff,
  paymentMethods,
  usePaymentUtils,
} from "./utils";

import { emmitQuoteFormDataProps } from "../../Services/Quote-service";
import { selectToken } from "../../Store/Auth/Selectors";
import { getPlansAsync } from "../../Store/Plans/Actions";
import { emmitQuoteAsync } from "../../Store/Payment/Actions";
import { generosPersona } from "../../helpers/constants";
import { getPersonTypesAsync } from "../../Store/PersonTypes/Actions";
import { getTaxConditionsAsync } from "../../Store/TaxConditions/Actions";
import { getTakerActivitiesAsync } from "../../Store/TakerActivities/Actions";
import { selectTakerActivitiesCombo } from "../../Store/TakerActivities/Selectors";
import { CheckboxWithReason } from "@experta/commons-v2.checkbox-with-reason";
import { getNationalitiesAsync } from "../../Store/Nationalities/Actions";
import { selectNationalitiesCombo } from "../../Store/Nationalities/Selectors";
import { paymentFormAsyncValidation } from "./paymentFormAsyncValidation";
import { PaymentDataForm } from "@experta/commons-v2.payment-data/dist/utils";
import { getCBUValidator } from "../../Services";
import { personalDataFormAsyncValidation } from "./personalDataAsyncValidations";
import { selectPersonTypes } from "../../Store/PersonTypes/Selectors";
import { selectTaxConditions } from "../../Store/TaxConditions/Selectors";
import { saveObligatedSubjectFormData, savePaymentFormDataStore, savePersonalFormData, savePoliticallyExposedFormData, savePropertyFormData } from "../../Store/Forms/Slice";
import { selectObligatedSubjectFormData, selectPaymentFormData, selectPersonalFormData, selectPoliticallyExposedFormData, selectPropertyFormData } from "../../Store/Forms/Selectors";
import { PaymentData } from "@experta/commons-v2.payment-data";


/**
 * Interface used as props.
 * @name PaymentPageProps
 */
export interface PaymentPageProps { }


/**
 * Main component.
 * @name PaymentPage
 * @param {PaymentPageProps}
 * @returns {<PaymentPage />}
 */
const PaymentPage = ({ }: PaymentPageProps) => {
  let history = useHistory();
  const dispatch = useAppDispatch();

  const selectedPlan = useAppSelector(selectSelectedPlan);

  const hash = useAppSelector(selectToken);

  const { tycParams, subjectObligatedParams } = usePaymentUtils();

  const localitiesComplete = useAppSelector(selectLocalities);
  const localitiesComboSelector = useAppSelector(selectLocalitiesCombo);
  const bienAsegurado = useAppSelector(selectBienAsegurados);
  const storePersonTypes = useAppSelector(selectPersonTypes);
  const storeTaxConditions = useAppSelector(selectTaxConditions);
  const takerActivities = useAppSelector(selectTakerActivitiesCombo);
  const nationalities = useAppSelector(selectNationalitiesCombo);

  // Form states
  const personalFormStoreData = useAppSelector(selectPersonalFormData);
  const propertyFormStoreData = useAppSelector(selectPropertyFormData);
  const politicallyExposedFormStoreData = useAppSelector(selectPoliticallyExposedFormData);
  const paymentFormStoreData = useAppSelector(selectPaymentFormData)
  const obligatedSubjectFormStoreData = useAppSelector(selectObligatedSubjectFormData);

  // Use data in Store as initial state of forms.
  // structuredClone id for make a ture copy and not mutate the store
  const [personalFormData, setPersonalFormData] = useState(Object.assign({}, personalFormStoreData));
  const [propertyFormData, setPropertyFormData] = useState(Object.assign({}, propertyFormStoreData));
  const [politicallyExposedFormData, setPoliticallyExposedFormData] = useState(Object.assign({}, politicallyExposedFormStoreData));
  const [paymentFormData, setPaymentFormData] = useState(Object.assign({}, paymentFormStoreData));
  const [obligatedSubjectFormData, setObligatedSubjectFormData] = useState(Object.assign({}, obligatedSubjectFormStoreData));

  const [localities, setLocalities] = useState<any>([]);

  useLayoutEffect(() => {
    (async () => {
      dispatch(showSpinner());
      await dispatch(getPersonTypesAsync());
      await dispatch(getNationalitiesAsync());
      await dispatch(getTaxConditionsAsync("FISICA"));
      await dispatch(getTakerActivitiesAsync());
      dispatch(hideSpinner());
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(!localities.length) {
      setLocalities([...localitiesComboSelector]);
    }
  }, [localities.length]);

  //useRefs
  const personalDataRef = useRef();
  const propertyDataRef = useRef();
  const paymentDataRef = useRef();
  const CheckboxWithReasonRef = useRef();


  const stepsPaymentParams = {
    currentStep: 2,
    stepsStyle: {
      color: "white",
      bgColor: "rgba(23, 160, 213, 0.7)",
      bgColorActive: "rgba(23, 160, 213, 1)",
    },
    data: [
      { title: lang.stepPayment.quote, icon: iconQuote },
      { title: lang.stepPayment.plans, icon: iconPlans },
      { title: lang.stepPayment.final, icon: iconPayment },
    ],
  };

  const fetchLocalities = async (zipCode: string) => {
    dispatch(showSpinner());
    await dispatch(getLocalitiesAsync(zipCode));
    dispatch(hideSpinner());
  };

  const handleChangePropertyData = (formData: PropertyPaymentDataFormProps) => {
    if (
      propertyFormData?.takerZipCode !== formData.takerZipCode &&
      formData.takerZipCode.length >= 4
    ) {
      fetchLocalities(formData.takerZipCode);
    }
    setPropertyFormData({...formData});
  };

  const fetchTaxConditions = async (personType: string) => {
    dispatch(showSpinner());
    await dispatch(getTaxConditionsAsync(personType));
    dispatch(hideSpinner());
  };

  const handleChangePersonalData = (formData: PersonalPaymentDataFormProps) => {
    if (personalFormData?.tipoPersona !== formData.tipoPersona){
      fetchTaxConditions(formData.tipoPersona);
    }
    setPersonalFormData({...formData});
  };

  const handleChangePaymentData = (formData: PaymentDataForm) => {
    /* This service call fill the bank name through the PaymentFormData state
    into PaymentData component field */
    if (formData.CBU?.length === 22) {
      dispatch(showSpinner());
      getCBUValidator()
        ?.validateCBU(formData.CBU)
        .then(({ data }) => {
          console.log('data: ', data)
          setPaymentFormData({
            ...formData,
            bankId: data?.banco.value,
            bankName: data?.banco.label,
          })
        })
        .catch((error) => console.error(error))
        .finally(() => dispatch(hideSpinner()));
    } else {
      setPaymentFormData(formData)
    }
  };

  const handleChangepoliticallyExposedData = (data: any) => {
    setPoliticallyExposedFormData({
      politicallyExposed: data.checkboxValue,
      reason: data.reason
    });
  };

  const handleChangeObligatedSubjectData = (data: any) => {
    setObligatedSubjectFormData({
      obligatedSubject: data.checkboxValue,
    })
  }

  const valuesInfoHeader = [
    { title: lang.infoHeader.zip, value: bienAsegurado?.direccion?.codPostal, },
    { title: lang.infoHeader.locality, value: bienAsegurado?.direccion?.localidad },
    { title: lang.infoHeader.propertyType, value: bienAsegurado?.tipoHogar },
    { title: lang.infoHeader.plan, value: formatPlanName(selectedPlan?.plan?.idPlan) },
    { title: lang.infoHeader.monthlyPrice, value: `$ ${selectedPlan?.plan?.premioMensual}` },
    { title: lang.infoHeader.durationCoverage, value: `${monthDiff(selectedPlan?.vencimiento)} MESES` },
  ];

  const validateStep = async () => {
    let isValid = true;
    const personalDataFormErrorMessages = await (
      personalDataRef.current as any
    ).validateForm();
    const propertyDataFormErrorMessages = (
      propertyDataRef.current as any
    ).validateForm();
    const paymentDataFormErrorMessages = await (
      paymentDataRef.current as any
    ).validateForm();
    const checkboxWithReasonErrorMessages = (
      CheckboxWithReasonRef.current as any
    ).validateData();

    if (
      !personalDataFormErrorMessages ||
      !propertyDataFormErrorMessages ||
      !paymentDataFormErrorMessages ||
      !checkboxWithReasonErrorMessages
    ) {
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = async () => {
    const clientData: emmitQuoteFormDataProps = Object.assign({}, personalFormData, propertyFormData, paymentFormData, politicallyExposedFormData, obligatedSubjectFormData)
    dispatch(showSpinner());
    const isValid = await validateStep()
    if (isValid) {
      dispatch(savePersonalFormData(personalFormData));
      dispatch(savePropertyFormData(propertyFormData));
      dispatch(savePoliticallyExposedFormData(politicallyExposedFormData));
      dispatch(saveObligatedSubjectFormData(obligatedSubjectFormData));
      dispatch(savePaymentFormDataStore(paymentFormData))
      dispatch(savePaymentFormData(clientData));
      await dispatch(emmitQuoteAsync({ hash: hash, formData: clientData }))
      await dispatch(getPlansAsync(hash));
      history.push("/gracias");
    }
    dispatch(hideSpinner());
  };

  const checkboxWithReasonDescription = politicallyExposedFormData.politicallyExposed ? tycParams.description : tycParams.altDescription

  return (
    <div>
      <div className="info-header-wrapper">
        <InfoHeader
          title={lang.infoHeaderTitle}
          insuredAssets={valuesInfoHeader}
        />
      </div>
      <StepsPayment
        currentStep={stepsPaymentParams.currentStep}
        style={stepsPaymentParams.stepsStyle}
        steps={stepsPaymentParams.data}
      />
      <div className="payment-container p-3 p-lg-5 mb-3">
        <PersonalData
          ref={personalDataRef}
          formData={personalFormData}
          handleChange={handleChangePersonalData}
          personTypes={storePersonTypes}
          takerActivities={takerActivities}
          taxConditions={storeTaxConditions}
          personSexs={generosPersona}
          nationalities={nationalities}
          validateMethod={personalDataFormAsyncValidation}
        />
        <CheckboxWithReason
          title={tycParams.title}
          description={checkboxWithReasonDescription}
          requiredCheckBox={false}
          showReasonTextInput
          returnInfo={handleChangepoliticallyExposedData}
          ref={CheckboxWithReasonRef}
        />
        <CheckboxWithReason
          title={subjectObligatedParams.title}
          description={subjectObligatedParams.description}
          requiredCheckBox={false}
          showReasonTextInput={false}
          returnInfo={handleChangeObligatedSubjectData}
        />
        <div className={"payment-form-title"}>
          <span>{lang.titleForm.propertyData}</span>
        </div>
        <PropertyData
          ref={propertyDataRef}
          formData={propertyFormData}
          handleChange={handleChangePropertyData}
          localitiesCombo={localities}
          localitiesComplete={localitiesComplete}
          bienAsegurado={bienAsegurado}
        />
        <div className="my-3">
          <PaymentData
            paymentMethods={paymentMethods}
            cardCompannies={cardBrands}
            formData={paymentFormData}
            handleChange={handleChangePaymentData}
            validateMethod={paymentFormAsyncValidation}
            ref={paymentDataRef}
          />
        </div>
        <div className="row">
          <div className="col-md-6 d-flex justify-content-md-start justify-content-center mb-3">
            <Button
              className="col-md-4 col-9 button-secondary-outline-ap"
              onClick={() => history.push(`/planes/${hash}`)}
            >
              {lang.backButton}
            </Button>
          </div>
          <div className="col-md-6 d-flex justify-content-md-end justify-content-center">
            <Button
              onClick={handleSubmit}
              className="col-md-4 col-9 button-primary-solid-ap"
            >
              {lang.payButton}
            </Button>
          </div>
        </div>
      </div>
      <div className="payment-banner-container my-3">
        {process.env.REACT_APP_SELLER_ORIGIN === "REBA" ? (
          <PaymentPrivacyBanner visa american_express />
        ) : (
          <PaymentPrivacyBanner
            mastercard
            naranja
            cabal
            visa
            visa_debito
            cencosud
            diners_club_international
            american_express
          />
        )}
      </div>
    </div>
  );
};

export default PaymentPage;