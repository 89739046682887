import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Switch, Route } from "react-router-dom";

import { HomePage, PaymentPage, PlansPage, FinalPage } from './Pages';
import { Template } from './Parts';
import { store } from './Store/store';
import { Provider } from 'react-redux';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <Router>
          <Template>
            <Switch>
              <Route exact path="/">
                <HomePage />
              </Route>
               <Route path="/planes/:hashId">
                <PlansPage/>
              </Route>
              <Route path="/pago/:hashId">
                <PaymentPage/>
              </Route>
              <Route path="/gracias">
                <FinalPage/>
              </Route>
            </Switch>
          </Template>
        </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();